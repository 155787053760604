<template>
  <transition
    enter-active-class="animated fadeInDownBig"
    leave-active-class="animated fadeOutUpBig"
  >
    <div
      class="User"
      v-show="show"
    >
      <div class="box">
        <div class="box-close" @click="close">
          <img src="@/assets/image/user/close.png" alt="關閉">
        </div>
        <!-- 登录 -->
        <login v-if="type == 1" @typeChange="typeChange" @close="close"></login>
        <!-- 注册 -->
        <register v-if="type == 2" @typeChange="typeChange"></register>
        <!-- 忘记密码 -->
        <forget-password v-if="type == 3" @typeChange="typeChange" @close="close"></forget-password>
      </div>
    </div>
  </transition>
</template>

<script>
import Login from "@/components/User/Login"
import Register from "@/components/User/Register"
import ForgetPassword from "@/components/User/ForgetPassword"
export default {
  name: 'User',
  components: {
    Login,
    Register,
    ForgetPassword
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: 1
    }
  },
  computed: {
    show: {
      get() {
        this.type = 1
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    // 關閉彈窗
    close() {
      this.show = false;
    },
    // 切换
    typeChange(val) {
      this.type = val
    }
  }
}
</script>

<style lang="less" scoped>
  .User {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    user-select: none;
    .box {
      margin: 250px auto 0;
      width: 568px;
      position: relative;
      .box-close {
        cursor: pointer;
        position: absolute;
        top: -100px;
        right: 0;
        padding: 5px;
        width: 27px;
        height: 27px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
@media screen and (max-width:750px) {
  .User {
    padding: 0 14px;
    width: calc(100% - 28px);
    .box {
      margin: 130px auto 0;
      width: 100%;
    }
  }
}
</style>