import store from '../store'

export function setSearchList(search) {
    const searchList = [...store.state.searchList]
    const index = searchList.findIndex(v => v === search)
    if (index > -1) {
        searchList.splice(index, 1)
        searchList.push(search)
    } else {
        searchList.push(search)
    }
    searchList.slice(-10)
    store.commit('setSearchList', searchList)
    window.localStorage.setItem('searchList', JSON.stringify(searchList))
}
