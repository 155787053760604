<template>
  <div id="app">
    <nav-header v-if="$route.path != '/404'"></nav-header>
    <router-view v-if="isRouterAlive"></router-view>
    <nav-footer v-if="$route.path != '/404'"></nav-footer>
    <messenger></messenger>
  </div>
</template>

<script>
import navHeader from "@/components/NavHeader";
import navFooter from "@/components/NavFooter";
import messenger from "@/components/messenger";
export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      //定义状态
      isRouterAlive: true
    };
  },
  // watch: {
  //   '$route' (to, from) {
  //     // this.$gtag.event('page_view',{ page_path: this.$route.path,'page_title':document.title })
  //     // console.log('this.$route.path',this.$route)
  //     // console.log(document.title)
  //   }
  // },
  mounted(){

  },
  metaInfo: {
    meta: [
      { name: 'Title', content: 'eSIMmart-Your best roaming data solution provider', vmid: 'Title' },
      { name: 'Author', content: 'eSImart', vmid: 'Author' },
      { name: 'Subject', content: 'A online market sells global eSIMs', vmid: 'Subject' },
      { name: 'Description', content: 'Discover the convenience of eSIMs at eSimart.com. Shop our wide range of eSIMs and enjoy seamless connectivity worldwide. Explore our roaming data solutions and experience hassle-free travel. Benefit from our reliable global telecom services and stay connected wherever you go. Find the perfect eSIM solution for your needs at eSimart.com', vmid: 'Description' },
      { name: 'Keywords', content: 'eSIM,Roaming data solutions,Global telecom service,Seamless connectivity,Hassle-free travel,Reliable connectivity,Worldwide eSIMs,Online eSIM store,International roaming,Global connectivity solutions', vmid: 'Keywords' },
      { name: 'Language', content: 'English', vmid: 'Language' },
      { property: 'og:type', content: 'article', vmid: 'og:type' },
      { property: 'og:image', content: '/favicon.ico', vmid: 'og:image' },
      { property: 'og:site_name', content: 'eSImart - An online eSIM mart', vmid: 'og:site_name' },
      { property: 'og:description', content: 'Discover the convenience of eSIMs at eSimart.com. Shop our wide range of eSIMs and enjoy seamless connectivity worldwide. Explore our roaming data solutions and experience hassle-free travel. Benefit from our reliable global telecom services and stay connected wherever you go. Find the perfect eSIM solution for your needs at eSimart.com', vmid: 'og:description' },
      { property: 'og:url', content: 'https://esimart.com', vmid: 'og:url' },
      { property: 'og:title', content: 'Buy eSIM Online - Global eSIMs for Sale at eSimart.com', vmid: 'og:title' }
    ]
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }

  },

  components: {
    navHeader,
    navFooter,
    messenger
  },
  computed: {
    isWhitePath: {
      get() {
        let arr = ['/details', '/product','/cart','/closing','/paymentResult','/search','/personal']
        if (arr.includes(this.$route.path)) {
          return false
        } else {
          return true
        }
      }
    }
  },

}
</script>
<style lang="less" scoped>
#app {
  padding-top: 82px;
  background: #0A0A14;
  //min-height: calc(100vh - 82px);
}
@media screen and (max-width: 800px) {
  #app {
    padding-top: 50px;
    //min-height: calc(100vh - 50px);
  }
}
</style>
<style>
html{background: #0A0A14;}
</style>
