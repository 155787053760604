import Vue from "vue";
import Vuex from "vuex";
import {Cart} from '../request/api'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {

    num: 0,
    shopList:[],
    xj:0,
    loginStatus: JSON.parse(window.localStorage.getItem('TOKEN_NAME'))?true:false,
    userInfo:JSON.parse(window.localStorage.getItem('userInfo')),
    headImg:JSON.parse(window.localStorage.getItem('userInfo'))?JSON.parse(window.localStorage.getItem('userInfo')).user_head_show:require('../assets/image/header/headImg.png'),
    searchList: window.localStorage.getItem('searchList') ? JSON.parse(window.localStorage.getItem('searchList')) : []
  },
  mutations: {
    addNum(store, payload) {
      store.num += payload
    },
    desNum(store, payload) {
      if (store.num > 0) {
        store.num -= payload
      }
    },
    changeStatus(state,status) {  //  重复赋值
      state.loginStatus = status;


    },
    changeuserInfo(state,userInfo){
      state.userInfo = JSON.parse(userInfo)
      state.headImg = JSON.parse(userInfo)?JSON.parse(userInfo).user_head_show?JSON.parse(userInfo).user_head_show:require('../assets/image/header/headImg.png'):require('../assets/image/header/headImg.png')
    },


    async changgetCart(state){
      if(JSON.parse(window.localStorage.getItem('TOKEN_NAME'))){
      let res = await Cart();
      state.shopList = res.data;
       state.num =state.shopList ? state.shopList.length : 0
       state.xj = 0
       res.data.forEach(item => {
        state.xj += Number(item.special_amount) * Number(item.num);
       });
    }else{
       let shop = window.sessionStorage.getItem('shopCart') ? JSON.parse(window.sessionStorage.getItem('shopCart')) : [];
       state.shopList = shop;
       state.num =shop.length;
       state.xj = 0
       shop.forEach(item => {
        state.xj += Number(item.special_amount) * Number(item.num);
       });
    }
    },

    setSearchList(state, searchList) {
      state.searchList = searchList
    }
  },
  actions: {},
  modules: {},
});
