<template>
  <div id="fb-customer-chat" class="fb-customerchat">
  </div>
</template>

<script>
export default {
  name: "messengerGroup",
  mounted() {
    const pageId = 120874477781384;
    const chatbox = document.getElementById('fb-customer-chat');
    chatbox.setAttribute("page_id", pageId);
    chatbox.setAttribute("attribution", "biz_inbox");

    (function(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    window.fbAsyncInit = function() {
      window.FB.init({
        xfbml: true,
        appId: "1039444747086916",
        version : 'v17.0',
        autoLogAppEvents : true,
      });
    };
  }
}
</script>

<style scoped>

</style>
