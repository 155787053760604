<template>
  <div class="NavFooter">
    <div class="box">
      <div class="box-top">
        <div class="box-top-d">
          <img src="@/assets/image/footer/logo.png" alt="logo">
          <div>An online eSIM mart</div>
          <span></span>
          <p>Discover the convenience of eSIMs at eSImart. Shop our wide range of eSIMs and enjoy seamless connectivity worldwide. Explore our roaming data solutions and experience hassle-free travel. Benefit from our reliable global telecom services and stay connected wherever you go. Find the perfect eSIM solution for your needs at eSImart</p>
          <span></span>
        </div>
        <div class="box-top-s">
          <h3>Support</h3>
          <div @click="$router.push('/product')">Buy eSIM</div>
          <div @click="$router.push('/affiliate')">Affiliate</div>
          <div @click="$router.push('/contactUs')">Contact Us</div>
        </div>
        <div class="box-top-s">
          <h3>eSImart</h3>
          <div @click="$router.push('/tutorial')">How to Use eSIM</div>
          <div @click="$router.push('/faq')">FAQ</div>
        </div>
        <div class="box-top-s">
          <h3>About</h3>
          <div @click="$router.push('/privacy')">Privacy  Policy</div>
          <div @click="$router.push('/terms')">Terms  & Conditions</div>
          <div @click="$router.push('/terms?id=refundPolicy')">Return Policy</div>
        </div>
        <div class="box-top-s">
          <h3 style="opacity: 0;">Hising technology co., ltd.</h3>
          <div>Email：service@esimart.com</div>
          <div>Service Hour：<span style="display: inline-block;margin-bottom: 20px">Monday to Friday<br><span style="position: absolute">09:30-20:00 (GMT+8)</span></span></div>
          <div><a href="https://www.facebook.com/esimartconnects" target="_blank"><img src="@/assets/image/icon/ico-facebook.png" /></a><a href="https://www.instagram.com/esimart_connects" target="_blank" style="margin-left: 15px;"><img src="@/assets/image/icon/ico-ins.png" /></a></div>

<!--          <div>Address：15 F., NO. 296, SEC. 4, XINYI RD., DA-AN DIST., TAIPEI CITY 10679, TAIWAN (R.O.C.)</div>-->
        </div>
      </div>
      <div class="box-line"></div>
      <div class="box-copyright">Copyright  2023 eSImart All Rights Reserved.</div>
<!--      <div class="box-copyright">© 2023 海興科技有限公司</div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "NavFooter"
}
</script>

<style lang="less" scoped>
.NavFooter {
  width: 100%;
  background: #272727;
  .box {
    padding: 54px 13px 0;
    margin: 0 auto;
    width: 1174px;
    .box-top {
      display: flex;
      padding: 0 10px 50px;
      .box-top-d {
        margin-right: 90px;
        width: 340px;
        img {
          width: auto;
          height: 20px;
        }
        div {
          margin: 9px 0 21px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #00A2FD;
        }
        span {
          display: none;
        }
        p {
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #FFFFFF;
        }
      }
      .box-top-s {
        padding: 0 25px;
        user-select: none;

        h3 {
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          height: 23px;
          color: #00A2FD;
        }
        div {
          padding: 4px 0;
          cursor: pointer;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
          &:first-of-type {
            margin-top: 18px;
          }
        }
        &:not(:last-of-type) {
          white-space: nowrap;
        }
      }
    }
    .box-line {
      width: 100%;
      height: 1px;
      background: #616161;
    }
    .box-copyright {
      padding: 18px 0 24px;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #FFFFFF;
    }
  }
}

// @media screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {
  .NavFooter {
    .box {
      padding: 44px 13px 0;
      margin: 0 auto;
      width: 1000px;
      .box-top {
        padding: 0 10px 40px;
        .box-top-d {
          margin-right: 70px;
          width: 320px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .NavFooter {
    .box {
      padding: 44px 13px 0;
      margin: 0 auto;
      width: 780px;
      .box-top {
        padding: 0 10px 30px 0px;
        .box-top-d {
          margin-right: 50px;
          width: 300px;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .NavFooter {
    width: 100%;
    .box {
      padding: 0 20px;
      width: calc(100% - 40px);
      .box-top {
        padding: 33px 0 14px;
        display: flex;
        flex-wrap: wrap;
        .box-top-d {
          margin-right: 0;
          margin-bottom: 35px;
          width: 100%;
          div {
            margin: 10px 0 14px;
          }
          span {
            display: block;
            width: 100%;
            height: 1px;
            background: #2E2E2E;
          }
          p {
            margin: 24px 0;
          }
        }
        .box-top-s {
          padding: 0 0 0 10px;
          width: calc((100% - 30px) / 2);
        }
        .box-top-s:last-of-type {width: 100%;}
      }
      .box-line {
        background: #2E2E2E;
      }
      .box-copyright {
        padding: 22px 0 31px;
      }
    }
  }
}
// @media screen and (max-width:750px) {}
</style>
