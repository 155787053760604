<template>
  <div class="NavHeader">
    <div class="box" :class="open?'open':''">
      <!-- PC端 -->
      <div class="box-left">
        <div class="box-left-logo">
          <img @click="$router.push('/')" src="@/assets/image/header/logo.png" alt="logo">
        </div>
        <div class="box-left-menu">
          <img v-show="!menuType" @click="menuTypeChange" src="@/assets/image/header/dashboard.png" alt="dashboard">
          <img v-show="menuType" @click="menuTypeChange" src="@/assets/image/header/menuClose.png" alt="menuClose">
          <!--eSIM Plans & Pricing-->
          <div @click="$router.push('/product')" style="white-space: nowrap;">eSIM product</div>
          <div @click="$router.push('/works')" style="white-space: nowrap;">How it works</div>
          <div style="opacity: 0;cursor: auto;display: none;">New Arrival</div>
          <div style="opacity: 0;cursor: auto;display: none;">Best Seller</div>
          <div style="opacity: 0;cursor: auto;display: none;">Blog </div>
          <div @click="$router.push('/faq')">FAQ</div>
          <div @click="$router.push('/aboutUs')">About Us</div>
          <div @click="$router.push('/contactUs')">Contact Us</div>
          <div @click="$router.push('/affiliate')">Affiliate</div>
        </div>
        <div class="box-left-search" v-if="$route.path !='/search'">
          <div class="search-box">
            <el-input placeholder="Search" v-model="search" @keyup.enter.native="seachEnter" autocomplete="off" class="input-with-select">
              <div slot="append" class="btn-img" @click="seachEnter"><img src="@/assets/image/search/search.png"  /></div>
            </el-input>
            <div class="mask" @click="open = false"></div>
          </div>
<!--          <input type="text" placeholder="Search" v >-->
        </div>
      </div>
      <div class="box-right" >
        <div class="box-right-gis" v-if="false">
          <img src="@/assets/image/header/translate.png" alt="gis">
        </div>
        <div class="box-right-shoppingCart" @mouseenter="shoppingCartShow = true" @mouseleave="shoppingCartShow = false" >
          <div class="shoppingCart-num" v-if="num>0">{{num}}</div>
          <img class="shoppingCart-img" src="@/assets/image/header/shoppingCart.png" alt="shoppingCart">
          <!-- 购物车弹窗 -->
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <div class="shoppingCart-popup" v-if="shoppingCartShow & $store.state.shopList.length > 0">
              <div class="shoppingCart-popup-num">
                {{$store.state.num}} item(s) of merchandise
              </div>
              <div v-if="$store.state.shopList && $store.state.shopList.length > 0">
                <div class="shoppingCart-popup-list">
                  <div class="list-div" v-for="(item, index) in $store.state.shopList.slice(0,5)" :key="index">
                    <div class="list-div-close">
                      <img src="@/assets/image/icon/icon-cartClose.png" alt="" @click="removeCart(item)">
                    </div>
                    <div class="list-div-div">
                      <img :src="item.image" alt="">
                      <div>
                        <p>{{item.name}}</p>
                        <span>{{ item.days + ' Days'}} {{ item.spec }}<small v-if="item.package && item.package.includes('By Day')">/ Day</small><span style="display: inline-block"> x {{ item.num }}</span></span>
                      </div>
                    </div>
                    <div class="list-div-price">USD ${{item.special_amount.toLocaleString()}}</div>
                  </div>
                </div>
                <div class="shoppingCart-popup-price">
                  Subtotal
                  <span>USD ${{($store.state.xj).toLocaleString('en-US',{minimumFractionDigits:1,maximumFractionDigits:1})}}</span>
                </div>
              </div>
              <div class="shoppingCart-popup-button" @click="$router.push('/cart')">
                View Shopping Cart
              </div>
            </div>
          </transition>
        </div>
        <div class="box-right-smallBell" v-if="false">
          <img src="@/assets/image/header/smallBell.png" alt="notice">
        </div>
        <div class="box-right-head" @click="opnePopup">
<!--          <img :src="$store.state.headImg" alt="profile" >-->
          <el-image :src="$store.state.headImg">
            <div slot="error" class="image-slot">
              <img src="@/assets/image/header/headImg.png" alt="profile" >
            </div>
          </el-image>
<!--          {{$store.state.loginStatus?'Member center':'Log in'}}-->
        </div>
      </div>

      <!-- 移动端 -->
      <div class="box-left-m">
        <div class="dashboard">
          <img v-show="!menuType" @click="menuTypeChange" src="@/assets/image/header/dashboard.png" alt="dashboard">
          <img v-show="menuType" @click="menuTypeChange" src="@/assets/image/header/menuClose.png" alt="menuClose">
        </div>
        <div class="logo">
          <img @click="$router.push('/')" src="@/assets/image/header/logo.png" alt="logo">
        </div>
      </div>
      <div class="box-right-m">
        <div class="search" @click="open = true">
          <img src="@/assets/image/header/search.png" alt="search">
        </div>
        <div class="shoppingCart" @mouseenter="shoppingCartShow = true" @mouseleave="shoppingCartShow = false" >
          <div class="shoppingCart-num" v-if="$store.state.num>0">{{$store.state.num}}</div>
          <img class="shoppingCart-img" src="@/assets/image/header/shoppingCart.png" alt="shoppingCart">
          <!-- 购物车弹窗 -->
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <div class="shoppingCart-popup" v-if="shoppingCartShow">
              <div class="shoppingCart-popup-num">
                {{$store.state.num}} item(s) of merchandise
              </div>
              <div class="shoppingCart-popup-list">
                <div class="list-div" v-for="(item, index) in $store.state.shopList" :key="index">
                  <div class="list-div-close">
                    <img src="@/assets/image/icon/icon-cartClose.png" alt="" @click="removeCart(item)">
                  </div>
                  <div class="list-div-div">
                    <img :src="item.image" alt="">
                    <div>
<!--                      <p>889898</p>-->
                      <p>{{item.name}}</p>
                      <span>{{ item.days + ' Days'}} {{ item.spec }}<small v-if="item.package && item.package.includes('By Day')">/ Day</small><span style="display: inline-block"> x {{ item.num }}</span></span>
                    </div>
                  </div>
                  <div class="list-div-price">USD ${{item.special_amount.toLocaleString()}}</div>
                </div>
              </div>
              <div class="shoppingCart-popup-price">
                Subtotal
                <span>USD ${{($store.state.xj).toLocaleString('en-US',{minimumFractionDigits:1,maximumFractionDigits:1})}}</span>
              </div>
              <div class="shoppingCart-popup-button" @click="$router.push('/cart')">
                View Shopping Cart
              </div>
            </div>
          </transition>
        </div>
        <div class="headImg" @click="opnePopup">
<!--          <img :src="$store.state.headImg" alt="頭像">-->
          <el-image :src="$store.state.headImg">
            <div slot="error" class="image-slot">
              <img src="@/assets/image/header/headImg.png" alt="profile" >
            </div>
          </el-image>
        </div>
      </div>
    </div>

    <div class="marquee" v-if="false"><span>First time purchase get extra 5% off, use code: WELCOME</span></div>
    <!-- 分类列表 -->
    <menu-type v-model="menuType" :fenlei ='fenlei' :areaList="areaList" @menuTypeChange="menuTypeChange"></menu-type>

    <!-- 登入 -->
    <user v-model="popupShow"></user>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState } from "vuex"
import User from "@/components/User/Index"
import MenuType from "@/components/MenuType"
import { getfenlei,getarea,deledtCart } from '../request/api'
import {setSearchList} from "@/utils/search";
export default {
  name: "NavHeader",
  components: { User, MenuType },
  data() {
    return {
      open:false,
      search:'',
      // 购物车显示状态
      shoppingCartShow: false,
      // 分类列表
      menuType: false,
      // 登录弹窗
      popupShow:false,
      // 购物车列表
      dataList: [
        {
          imgUrl: require('@/assets/image/header/shoppingCart1.png'),
          name: '美國eSIM網路卡',
          title: '5日無限流量',
          num: 1,
          price: '174'
        },
        {
          imgUrl: require('@/assets/image/header/shoppingCart1.png'),
          name: '美國eSIM網路卡',
          title: '5日無限流量',
          num: 1,
          price: '174'
        },
      ],
      //分類列表
      fenlei:[],
      //國家列表
      areaList:[],
      userlogin:JSON.parse(window.localStorage.getItem('TOKEN_NAME'))?'Member center':'Log in',
      headImg:require('../assets/image/header/headImg.png')
    }
  },
  created(){
     this.getfenleiList();
     this.getareaList();

      this.$store.commit('changgetCart')



  },
  methods: {
    // 購物車增加
    addNum() {
      this.$store.commit('addNum', 2)
    },
    // 打開登入彈窗
    opnePopup() {
      if(window.localStorage.getItem('TOKEN_NAME')){
        this.$router.push({
        path: '/personal',
        query: {}
      })
      }else{
        this.popupShow = true
      }

    },


     //刪除購物車
     async removeCart(item) {
      if(window.localStorage.getItem('TOKEN_NAME')){
      let data = {
        plan_id: item.plan_id,
        price_id: item.price_id
      }
      let res = await deledtCart(data)
      if (res.code == 1) {
        this.$store.commit('changgetCart')
        // console.log('this.$store.state.shopList.length',this.$store.state.shopList.length)
        if(this.$store.state.shopList.length<2){
          this.shoppingCartShow = false
        }
      }}else{
      let cart =  JSON.parse(window.sessionStorage.getItem('shopCart')) ;
      cart.splice(cart[cart.findIndex(iten => iten == item)],1);
      window.sessionStorage.setItem('shopCart',JSON.stringify(cart));
      this.$store.commit('changgetCart')
      }
    },

    // 分类窗口
    menuTypeChange() {
      this.menuType = !this.menuType;
      $("body").toggleClass("scroll_disabled");
    },
    seachEnter(){
      setSearchList(this.search)
      this.open = false
      this.$router.push({
        path: '/search',
        query: {
          content: this.search
        }
      })
    },

     //獲取分類
     async getfenleiList(){
          let res = await getfenlei();
          this.fenlei = res.data
      },

      //獲取國家列表
      async getareaList(){
         let res = await getarea();
         this.areaList = res.data
      },
       //登入狀態
     loginstatus(i){
         if(i == true){
            this.userlogin = 'Member center'
         }else{
          this.userlogin = 'Log in'
         }
     },
  },
  computed:{
    ...mapState(['num']),
    status() {  //  计算属性
    return this.$store.state.loginStatus; //  Vuex 中定义的属性
  }
  },
  watch:{
     status(){
      if(this.$store.state.loginStatus == true){
        this.loginstatus(true)
     }else{
        this.loginstatus(false)
     }
    }
  }
}
</script>

<style lang="less" scoped>
.NavHeader {
  width: 100%;
  background: #0A0A14;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  user-select: none;
  z-index: 99;
  .box{
    margin: 0 auto;
    padding: 0 13px;
    width: 1174px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .box-left {
      display: flex;
      align-items: center;
      flex-grow: 1;
      .box-left-logo {
        cursor: pointer;
        width: auto;
        height: 21px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-left-menu {
        margin: 0 100px 0 56px;
        display: flex;
        align-items: center;
        img {
          cursor: pointer;
          padding: 20px;
          width: 20px;
          height: 20px;
        }
        div {
          cursor: pointer;
          padding: 20px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #FFFFFF;
        }
      }
      .box-left-search {
        //width: 180px;
        height: 30px;
        margin-left: auto;
        input {
          padding: 0 18px;
          width: 100%;
          height: 100%;
          background: #2E2E2E;
          border-radius: 20px;
          outline: none;
          border: none;
          color: #FFFFFF;
        }
      }
    }

    .box-right {
      display: flex;
      align-items: center;
      .box-right-gis {
        padding: 16px;
        cursor: pointer;
        width: 35px;
        height: 35px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-right-shoppingCart {
        padding: 16px;
        width: 21px;
        height: 28px;
        position: relative;
        .shoppingCart-img {
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
        .shoppingCart-num {
          cursor: pointer;
          position: absolute;
          top: 22px;
          left: 30px;
          min-width: 12px;
          height: 12px;
          padding: 4px;
          background: #0B57C9;
          border-radius: 50%;
          color: #ffffff;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          text-align: center;
        }
        .shoppingCart-popup {
          position: absolute;
          top: 50px;
          left: calc(50% - 171px);
          width: 342px;
          padding-bottom: 10px;
          background: #FFFFFF;
          border-radius: 10px;
          .shoppingCart-popup-num {
            display: flex;
            align-items: center;
            padding: 0 16px;
            height: 44px;
            background: #2E2E2E;
            border-radius: 10px 10px 0px 0px;
            font-family: 'Noto Sans TC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #D9D9D9;
          }
          .shoppingCart-popup-list {
            .list-div {
              padding: 18px 20px 18px 12px;
              border-bottom: 1px solid #D9D9D9;
              display: flex;
              align-items: center;
              .list-div-close {
                margin-right: 15px;
                cursor: pointer;
                user-select: none;
                width: 16px;
                height: 16px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .list-div-div {
                cursor: pointer;
                width: calc(100% - 131px);
                display: flex;
                align-items: center;
                img {
                  margin-right: 10px;
                  width: 45px;
                  height: 45px;
                  border-radius: 5px;
                }
                div {
                  width: calc(100% - 55px);
                  font-family: 'Noto Sans TC';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #130F26;
                }
              }
              .list-div-price {
                text-align: right;
                width: 100px;
                font-family: 'Noto Sans TC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #0A0A14;
              }
            }
          }
          .shoppingCart-popup-price {
            padding: 10px 20px 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-family: 'Noto Sans TC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #2E2E2E;
            span {
              margin-left: 16px;
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 23px;
              color: #130F26;
            }
          }
          .shoppingCart-popup-button {
            cursor: pointer;
            user-select: none;
            margin: 28px auto 0;
            width: 216px;
            height: 37px;
            background: #130F26;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Noto Sans TC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #FFFFFF;
          }
        }
      }
      .box-right-smallBell {
        cursor: pointer;
        padding: 10px;
        width: 27px;
        height: 29px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-right-head {
        cursor: pointer;
        padding: 3px;
        width: fit-content;
        padding-right: 10px;
        height: 28px;
        display: flex;
        align-items: center;
        background: #272727;
        border-radius: 28px;
        font-family: 'Noto Sans TC';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        img {
          margin-right: 7px;
          width: 32px;
          height: 32px;
        }
      }
    }

    .box-left-m, .box-right-m {
      display: none;
    }
  }
}

// @media screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {
  .NavHeader {
    .box {
      width: 1000px;
      .box-left {
        .box-left-menu {
          margin: 0 20px 0 46px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .NavHeader {
    .box {
      width: 780px;
      .box-left {
        .box-left-menu {
          margin: 0 5px 0 26px;
          img {
            padding: 10px;
          }
          div {
            padding: 10px;
          }
        }
        .box-left-search {
          //width: 150px;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .NavHeader {
    .box {
      width: calc(100% - 40px);
      height: 50px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      .box-left{
        flex-grow: 0;
        .box-left-logo , .box-left-menu{
          display: none;
        }
      }
      .box-right {
        display: none;
      }
      .box-left-m {
        display: flex;
        align-items: center;
        margin-right: auto;
        .dashboard {
          margin-right: 8px;
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .logo {
          width: auto;
          height: 21px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .box-right-m {
        display: flex;
        align-items: center;
        .search {
          //padding: 8px;
          padding: 0px;
          width: 26px;
          height: 29px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .shoppingCart {
          padding: 8px;
          width: 26.71px;
          height: 26px;
          position: relative;
          .shoppingCart-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .shoppingCart-num {
            position: absolute;
            top: 15px;
            left: 22px;
            min-width: 12px;
            height: 12px;
            padding: 4px;
            background: #0B57C9;
            border-radius: 50%;
            color: #ffffff;
            font-family: 'Noto Sans TC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
          }
          .shoppingCart-popup {
            position: absolute;
            top: 50px;
            right: -48px;
            width: calc(100vw - 32px);
            padding-bottom: 10px;
            background: #FFFFFF;
            border-radius: 10px;
            .shoppingCart-popup-num {
              display: flex;
              align-items: center;
              padding: 0 16px;
              height: 44px;
              background: #2E2E2E;
              border-radius: 10px 10px 0px 0px;
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #D9D9D9;
            }
            .shoppingCart-popup-list {
              .list-div {
                padding: 18px 20px 18px 12px;
                border-bottom: 1px solid #D9D9D9;
                display: flex;
                align-items: center;
                .list-div-close {
                  margin-right: 15px;
                  cursor: pointer;
                  user-select: none;
                  width: 16px;
                  height: 16px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .list-div-div {
                  cursor: pointer;
                  width: calc(100% - 131px);
                  display: flex;
                  align-items: center;
                  img {
                    margin-right: 10px;
                    width: 45px;
                    height: 45px;
                    border-radius: 5px;
                  }
                  div {
                    width: calc(100% - 55px);
                    font-family: 'Noto Sans TC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #130F26;
                  }
                }
                .list-div-price {
                  text-align: right;
                  width: 100px;
                  font-family: 'Noto Sans TC';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #0A0A14;
                }
              }
            }
            .shoppingCart-popup-price {
              padding: 10px 20px 0;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              color: #2E2E2E;
              span {
                margin-left: 16px;
                font-family: 'Noto Sans TC';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: #130F26;
              }
            }
            .shoppingCart-popup-button {
              cursor: pointer;
              user-select: none;
              margin: 28px auto 0;
              width: 216px;
              height: 37px;
              background: #130F26;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 23px;
              color: #FFFFFF;
            }
          }
        }
        .headImg {
          margin-left: 0px;
          width: 26px;
          height: 26px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.NavHeader .box .box-right .box-right-shoppingCart{width: 33px;height: 33px;    padding: 16px 10px;}
.NavHeader .box .box-right .box-right-shoppingCart .shoppingCart-img{object-fit: contain;}
.NavHeader .box .box-right .box-right-shoppingCart .shoppingCart-num{top: 27px;}
.NavHeader .box .box-right .box-right-head{height: 31px;}
.NavHeader{background: rgba(0, 0, 0, 0.75);}
@media screen and (max-width: 800px) {
}

// @media screen and (max-width:750px) {}
</style>
