<template>
  <div class="Login">
    <h1 class="box-h1">Login</h1>
    <div class="box-register">
      <span>Sign in to find more at member center</span>
      <div @click="register">Register account</div>
    </div>
    <div class="box-input">
      <input type="text" v-model="email" placeholder="Please enter your email">
    </div>
    <div class="box-input">
      <input type="password" v-model="password" placeholder="Please enter your password" data="passworedtype">
    </div>
    <div class="box-forget">
      <span @click="register">Become a member by registering.</span>
      <div @click="forget">Forgot password?</div>
    </div>
    <div class="box-login" @click="login">Login</div>
    <div class="box-google">
      <div @click="googleLogin">
        <img src="@/assets/image/user/google.png" alt="google">
        Sign in with google
      </div>
    </div>
  </div>
</template>

<script>
import  {popupShow} from '../NavHeader'
import { login,userInformation } from '@/request/api'
import { timingSafeEqual } from 'crypto'
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    // 註冊會員
    register() {
      this.$emit('typeChange', 2)
    },
    // 忘記密碼
    forget() {
      this.$emit('typeChange', 3)
    },
    // 會員登入
   async login() {
      let data = {
        account: this.email,
        password: this.password
      }
      let rep = await login(data)
      if(rep.code == 1){
        window.localStorage.setItem('TOKEN_NAME', JSON.stringify(rep.data.token))
        this.getUserxinxi()
        this.$emit('close', false)
       this.$nextTick(() =>{
        this.$store.commit("changeStatus", true);
        this.$store.commit('changgetCart')
       })
        this.email = '';
        this.password = '';
        if(this.$route.path == '/'){
          this.$router.push({
        path: '/personal',
        query: {}
      })
    }else{
      this.$router.push(this.$route.fullpath)}
      }else{
        this.$message({
          type:'warning',
          message:rep.msg
        })
      }

    },
    // 谷歌登入
    googleLogin() {
      window.location = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=online&client_id=866710459170-kjghebt559shf6or8cu6sl6vhdp87upj.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fapi.esimart.com%2Fapi%2FFront%2Fgoogle_login&state&scope=email%20profile&approval_prompt=auto'
    },
      //獲取用戶信息
   async getUserxinxi(){
        let res = await userInformation();
        window.localStorage.setItem('userInfo',JSON.stringify(res.data.user))
        this.$nextTick(() =>{
        this.$store.commit("changeuserInfo", JSON.stringify(res.data.user));
       })
    }
  }
}
</script>

<style lang="less" scoped>
  .Login {
    .box-h1 {
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 46px;
      color: #FFFFFF;
    }
    .box-register {
      margin: 10px 0 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #00A2FD;
        max-width: 68%;
      }
      div {
        cursor: pointer;
        padding-right: 20px;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #5CE9C7;
      }
    }
    .box-input {
      margin-bottom: 17px;
      width: 100%;
      height: 50px;
      input {
        padding: 0 16px;
        width: 100%;
        height: 100%;
        background: #2E2E2E;
        border: 1px solid #2E2E2E;
        border-radius: 10px;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #D9D9D9;
      }
      input:focus {
        border: 1px solid #5CE9C7;
      }
      input::-webkit-input-placeholder{
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #616161;
      }
      input::-moz-placeholder{
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #616161;
      }
      input::-moz-placeholder{
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #616161;
      }
      input::-ms-input-placeholder{
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #616161;
      }
    }
    .box-forget {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #FFFFFF;
      span {
        display: none;
      }
    }
    .box-login {
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto 30px;
      width: 216px;
      height: 37px;
      background: #0B57C9;
      border-radius: 20px;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
    }
    .box-google {
      display: flex;
      justify-content: center;
      div {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #D9D9D9;
        img {
          margin-right: 12px;
          width: 35px;
          height: 35px;
        }
      }
    }
  }
@media screen and (max-width:750px) {
  .Login {
    .box-register {
      padding-left: 7px;
      margin: 10px 0 16px;
      div {
        display: none;
      }
    }
    .box-input {
      margin-bottom: 24px;
    }
    .box-forget {
      padding-left: 14px;
      justify-content: space-between;
      span {
        display: block;
      }
    }
    .box-login {
      margin: 60px auto 30px;
      width: 100%;
    }
  }
}
</style>
