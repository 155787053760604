import { Service } from './index'
export function getConfigsByProductId(productId) {
  return Service({
    url: '/manager/getConfigsByProductId',
    params: { productId: productId }
  })
}
export function getAllAndroidPlugins() {
  return Service({
    url: '/manager/getAndroidPlugin ',
    method: 'get'
  })
}
export function addNewAndroidPlugin(data) {
  return Service({
    url: '/manager/addAndroidPlguin',
    data: JSON.stringify(data)
  })
}

//獲取首頁banner
export function getIndexbanner(data) {
  return Service({
    url: '/Front/banner',
    data: data
  })
}

//獲取產品列表
export function getProduct(data) {
  return Service({
    url: '/Front/plan_list',
    data: data
  })
}
//獲取產品列表(首頁)
export function getPlanList(data) {
  return Service({
    url: '/Front/home_plan_list',
    data: data
  })
}

//獲取分類列表
export function getfenlei(data) {
  return Service({
    url: '/Front/type_list',
    data: data
  })
}

//獲取地區
export function getarea(data) {
  return Service({
    url: '/Front/get_area_country',
    data: data
  })
}

//產品詳情
export function getProductDetails(data) {
  return Service({
    url: '/Front/plan_detail',
    data: data
  })
}
// 詳情獲取套餐
export function getGroupByDays(data) {
  return Service({
    url: '/Front/plan_detail_group_by_days',
    data
  })
}
// 獲取匯率
export function getCurrentRate(data) {
  return Service({
    url: 'Front/get_current_rate',
    data
  })
}

//發送驗證碼
export function getyzm(data) {
  return Service({
    url: '/Front/send_code',
    data: data
  })
}

//驗證驗證碼
export function Validation(data) {
  return Service({
    url: '/Front/check_code',
    data: data
  })
}

//注冊
export function Registration(data) {
  return Service({
    url: '/Front/register',
    data: data
  })
}

//登入
export function login(data) {
  return Service({
    url: '/Front/login',
    data: data
  })
}

//忘記密碼
export function forgotPassword(data) {
  return Service({
    url: '/Front/password_send',
    data: data
  })
}

//獲取用戶信息
export function userInformation(data) {
  return Service({
    url: '/Info/get_user_info',
    data: data
  })
}

//更新用戶信息
export function updateuserInformation(data) {
  return Service({
    url: '/Info/update_user_info',
    data: data
  })
}

//上傳頭像
export function uploadHead(data) {
  return Service({
    url: '/Info/file_upload_head',
    data: data
  })
}


//加入購物車
export function getCart(data) {
  return Service({
    url: '/Car/add_car',
    data: data
  })
}

//批量加入購物車
export function getallCart(data) {
  return Service({
    url: '/Car/batch_add_car',
    data: data
  })
}

//購物車
export function Cart(data) {
  return Service({
    url: '/Car/get_my_car',
    data: data
  })
}

//購物車數量加減
export function carNum(data) {
  return Service({
    url: '/Car/change_car_num',
    data: data
  })
}

//使用優惠碼
export function codeCheck(data) {
  return Service({
    url: '/Car/code_check',
    data: data
  })
}


//刪除購物車
export function deledtCart(data) {
  return Service({
    url: '/Car/remove_car',
    data: data
  })
}

//下單
export function placeOrder(data) {
  return Service({
    url: '/Order/order_add',
    data: data,


  })
}

//訂單列表
export function getOrderList(data) {
  return Service({
    url: '/Order/order_list',
    data: data
  })
}

//取消訂單
export function CancelOrder(data) {
  return Service({
    url: '/Order/order_cancel',
    data: data
  })
}

//訂單詳情
export function orderDetails(data) {
  return Service({
    url: '/Order/order_detail',
    data: data
  })
}

//去支付
export function toPay(data) {
  return Service({
    url: '/Order/order_pay',
    data: data
  })
}

//支付前修改訂單信息

export function updatePreOrder(data) {
  return Service({
    url: '/Order/pre_order_update',
    data: data
  })
}
// 新的api
export function toTaPay(data) {
  return Service({
    url: '/Order/order_tappay',
    data: data
  })
}
export function tutorial(data) {
  return Service({
    url: '/Info/getting_started',
    data: data
  })
}
export function contactUs(data) {
  return Service({
    url: '/Info/contact_us',
    data: data
  })
}
export function FaqClass(data) {
  return Service({
    url: '/Help/faq_class',
    data: data
  })
}
export function FaqList(data) {
  return Service({
    url: '/Help/faq_list',
    data: data
  })
}

// 联系我们
export function touchUs(data) {
  return Service({
    url: '/Front/touch_us',
    data: data,
  })
}

// faq 分類
export function faqType(data) {
  return Service({
    url: '/Front/faq_type_list',
    data
  })
}
// faq 列表
export function faqList(data) {
  return Service({
    url: '/Front/faq_list',
    data
  })
}
// affiliateSign 發信件
export function emailContactUs(data) {
  return Service({
    url: '/Front/email_contact_us',
    data
  })
}
